import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BookCover from "./BookCover"
import Slider from "./lib/Slider"

export default function BookList({ filter, slidesToShow, withSlider }) {
  const { atril } = useStaticQuery(query)
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: slidesToShow ? slidesToShow : 4,
    slidesToScroll: slidesToShow ? slidesToShow : 4,
    autoplay: false,
    autoplaySpeed: 4000,
    className: "overflowVisible",
  }
  if (withSlider)
    return (
      <Slider settings={settings} className="max-w-screen-xl mx-auto">
        {atril.books
          .filter(book => (book.es_libro === 1 ? book : null))
          .filter(book => (filter ? filter === book.author : book))
          .map(book => (
            <BookCover key={book.id} data={book} />
          ))}
      </Slider>
    )
  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="grid grid-cols-1 xs:grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-10 py-12 px-5">
        {atril.books
          .filter(book => (book.es_libro === 1 ? book : null))
          .filter(book => (filter ? filter === book.author : book))
          .map(book => (
            <BookCover key={book.id} data={book} />
          ))}
      </div>
    </div>
  )
}

const query = graphql`
  query {
    atril {
      books {
        id
        titulo
        subtitulo
        image
        color
        author
        es_libro
      }
    }
  }
`
