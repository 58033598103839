import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import Image from "gatsby-image"
import styled from "styled-components"
import { Container } from "../components/lib/Container"
import { Globe, Facebook, Linkedin, ArrowLeft } from "react-feather"
import BookList from "./../components/BookList"
import Footer from "../components/lib/Footer"

const SocialLink = styled(Link)`
  display: flex;
  font-weight: 600;
  &:hover {
    color: tomato;
  }
`

const Publicaciones = styled.small`
  small {
    margin-right: 0.5rem;
    &:not(:last-child) {
      &:after {
        content: "|";
        margin-left: 0.5rem;
        color: lightgray;
      }
    }
  }
`

const ButtonMore = styled.button`
  position: absolute;
  left: 0;
  bottom: -25px;
  font-weight: bold;
`

const Author = ({ data }) => {
  const { authorsYaml: author } = data
  const [moreText, setMoreText] = useState(true)
  const maxStringLength = 450
  return (
    <>
      <Container className="!pt-28">
        <div className="max-w-screen-xl mx-auto">
          <Link
            to="/autores"
            className="sticky top-[5rem] z-10 rounded-full w-10 h-10 flex justify-center items-center backdrop-blur-lg bg-white/90"
          >
            <ArrowLeft color="currentColor" strokeWidth={1} size={16} />
          </Link>
          <div className="flex flex-col md:flex-row gap-5 md:gap-8 mb-20">
            <div className="-mt-14 mx-auto">
              <Image
                className="rounded-full"
                fixed={data.file.childImageSharp.fixed}
              />
            </div>
            <div>
              <div className="text-center md:text-left mb-6">
                <h1 className="text-5xl font-bold mt-5 mb-3">{author.name}</h1>
                <h2 className="text-2xl">{author.role}</h2>
                <div className="flex space-x-3 my-3 justify-center md:justify-start ">
                  <SocialLink to="">
                    <Globe color="currentColor" strokeWidth={1.3} size={16} />
                  </SocialLink>
                  <SocialLink to="">
                    <Facebook
                      color="currentColor"
                      strokeWidth={1.3}
                      size={16}
                    />
                  </SocialLink>
                  <SocialLink to="">
                    <Linkedin
                      color="currentColor"
                      strokeWidth={1.3}
                      size={16}
                    />
                  </SocialLink>
                  {author.alias && (
                    <SocialLink size="xs">
                      <small>{author.alias}</small>
                    </SocialLink>
                  )}
                  <Publicaciones>
                    <small>
                      <b>2</b> Libros
                    </small>
                    <small>
                      <b>5</b> Articulos
                    </small>
                  </Publicaciones>
                </div>
              </div>
              <div className="relative">
                <div
                  className="space-y-3 leading-relaxed"
                  dangerouslySetInnerHTML={{
                    __html:
                      moreText && author.about.length >= maxStringLength
                        ? _.truncate(author.about, {
                            length: maxStringLength,
                            separator: /,? +/,
                          })
                        : author.about,
                  }}
                />
                {author.about.length >= maxStringLength ? (
                  <ButtonMore onClick={() => setMoreText(!moreText)}>
                    {moreText ? `Ver más ↓` : `Ver menos ↑`}
                  </ButtonMore>
                ) : null}
              </div>
            </div>
          </div>
          <h2 className="text-2xl mb-8 font-bold">Publicaciones</h2>
          <BookList filter={author.name} />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($name: String!, $image: String!) {
    authorsYaml(name: { eq: $name }) {
      name
      about
      role
      alias
    }
    file(relativePath: { eq: $image }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Author
