import React from "react"
import { motion } from "framer-motion"

export const Container = ({ children, className, bgColor }) => (
  <motion.div
    className={`relative px-5 py-20 md:px-10 md:py-28 ${className}`}
    style={{ backgroundColor: bgColor }}
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    }}
    exit={{
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    }}
  >
    {children}
  </motion.div>
)
