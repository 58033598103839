import { AtrilflamencoIcon } from "@atrilflamenco/react-components"
import React from "react"

export default function Footer() {
  return (
    <div className="px-5 py-8 !sm:p-10 border-t">
      <div className="max-w-screen-xl mx-auto flex flex-col-reverse lg:flex-row gap-20 md:gap-32 justify-between">
        <div className="flex flex-col justify-between space-y-5 lg:h-56">
          <AtrilflamencoIcon size="lg" />
          <p className="leading-tight">
            <small>
              {`Copyright © ${new Date().getFullYear()} Bernat Jiménez de Cisneros Puig`}
              <br />
              All rights reserved.
            </small>
          </p>
        </div>
        <div className="p-2 flex flex-wrap gap-12 xl:gap-28 text-gray-600">
          <div className="flex flex-col space-y-2">
            <h2 className="font-bold text-sm uppercase mb-1">Contenidos</h2>
            <a href="/">Libros</a>
            <a href="/">Artículos</a>
            <a href="/">Planes de lectura</a>
          </div>
          <div className="flex flex-col space-y-2">
            <h2 className="font-bold text-sm uppercase mb-1">Sobre</h2>
            <a href="/">Autores</a>
            <a href="/">Suscripció</a>
          </div>
          <div className="flex flex-col space-y-2">
            <h2 className="font-bold text-sm uppercase mb-1">Contact</h2>
            <a href="/">Twitter</a>
            <a href="/">Facebook</a>
          </div>
          <div className="flex flex-col space-y-2">
            <h2 className="font-bold text-sm uppercase mb-1">Legal</h2>
            <a href="/">Aviso legal</a>
            <a href="/">Política de privacidad</a>
          </div>
        </div>
      </div>
    </div>
  )
}
