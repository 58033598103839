import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import { motion } from "framer-motion"

export default function BookCover({ data: book }) {
  return (
    <motion.div
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.99 }}
      className="shadow-xl"
    >
      <Link
        className="rounded relative md:h-80"
        style={{ padding: "0px", height: "370px" }}
        to={`/lectura/${_.kebabCase(book.titulo)}`}
      >
        <div className="overflow-hidden w-full h-full">
          <img
            className="object-cover w-full h-full"
            src={`https://api.atrilflamenco.com:5000/${
              !!book.image ? book.image : "Libros/default.png"
            }`}
            alt={`Portada ${book.titulo}`}
          />
        </div>
      </Link>
    </motion.div>
  )
}
